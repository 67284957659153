<template>
  <validation-provider :name="label||placeholder" :rules="rules" v-slot="validationContext">
    <b-form-group :label="label" :label-for="id">
      <b-form-input :name="id" hidden :state="getValidationState(validationContext)" :aria-describedby="`${id}-feedback`" :id="id" :value="value"></b-form-input>
      <v-select :disabled="disabled" :clearable="false" :placeholder="placeholder" @search:focus="openSelect" @option:selected="changed" :class="[{'invalid': validationContext.errors[0]},classSize]" :value="value" @input="handleInput" :options="data" label="label" :reduce="e => e.value" @search="onSearchData">
        <template #option="item">
          <small><em>{{item.header}}</em></small> <br>
          <span class="font-weight-bold">{{item.label}}</span>
        </template>
        <template v-slot:no-options="{search,searching}">
          <template v-if="searching">
            Tidak ada {{label}} ditemukan untuk "<em>{{search}}</em>".
          </template>
          <em style="opacity:0.8;" v-else>Ketik untuk mulai mencari {{label}}.</em>
        </template>
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes">🔽</span>
        </template>
      </v-select>
      <b-form-invalid-feedback :id="`${id}-feedback`">{{validationContext.errors[0]}}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import id from "vee-validate/dist/locale/id.json"
import { localize, extend, ValidationProvider } from 'vee-validate';
import * as rules from "vee-validate/dist/rules";

import {BFormGroup,BFormInvalidFeedback,BFormInput} from 'bootstrap-vue'
import vSelect from 'vue-select'
import _ from 'lodash'
import axios from '@axios'
import { v4 as uuidv4 } from 'uuid'


Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
localize('id', id)

export default {
  props:{
    label:{
      required: true,
      type: String
    },
    size:{
      required: false,
      type: String
    },
    placeholder:{
      required: false,
      type: String
    },
    url:{
      required: true,
      type: String
    },
    value:{
      required: false,
    },
    rules:{
      required: false,
      type: Object
    },
    disabled:{
      type: Boolean,
      default:false
    },
    queryparams:{
      type:Object
    }
  },
  data(){
    return {
      search:'',
      data:[],
      id: ""
    }
  },
  computed:{
    classSize(){
      if (!this.size) {
        return ''
      } else {
        return 'select-size-'+this.size
      }
    }
  },
  components:{
    BFormGroup,
    vSelect,
    ValidationProvider,
    BFormInvalidFeedback,
    BFormInput
  },
  methods:{
    changed(val){
      this.$emit('value-changed',val)
    },
    handleInput(value){
      this.$emit('input', value)
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    initOption(value){
      this.data = value
    },
    openSelect(){
      this.loadData()
    },
    flattenObject(n){
      return n.options.map(e => {
        return {
          value: e.value,
          label: e.label,
          header: n.label,
          ...e
        }
      })
    },
    async loadData(search=''){
      if(!this.url) return Promise.resolve(this.data)
      try {
        let { data } = await axios.get(this.url,{params:{name:search,...this.queryparams}})
        let options = []
        const selected = this.data.filter(d => d.value==this.value)
        if(selected.length>0) options = _.uniqBy(_.flattenDeep([_.flatMap(data,this.flattenObject),selected]) ,'value')
        else options = _.flatMap(data,this.flattenObject)
        this.data = options
        return Promise.resolve(data)
      } catch (error) {
        this.handleError(error)
        return Promise.reject(error)
      }
    },
    onSearchData(search,loading){
      if(search.length){
        loading(true);
        this.searchData(loading,search,this)
      }
    },
    searchData: _.debounce((loading,search,vm) => {
      vm.loadData(search).then(() => {
        loading(false)
      })
    },350),
  },
  created(){
    this.id = uuidv4()
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .invalid .vs__dropdown-toggle {
    border-color: #ea5455;
  }
  .valid .vs__dropdown-toggle {
    border-color: #28c76f;
  }
</style>