<template>
  <b-row>
    <b-col sm="12" md="12">
      <my-card :isloading="cardloading" :title="code">
        <template slot="body">
          <form-validation @submit="submitForm">
            <b-row>
              <b-col sm="12" md="7">
                <form-select :filter-id="idBranchArray" :clearable="false" ref="branchSelect" url="v1/branch_select2" :queryparams="{id_regional: mainRegionalId}" @value-changed="branch_name = $event.label;getBalance()" :rules="{required: true}" v-model="id_branch" label="Cabang"></form-select>
              </b-col>
              <b-col v-if="$route.params.id" sm="12" md="5" class="mt-1 mb-1">
                <table class="w-100">
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Di Input Oleh :</td>
                      <td class="text-right">{{inputInfo.user_create}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Di Update Oleh :</td>
                      <td class="text-right">{{inputInfo.user_update}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Di Approve Oleh :</td>
                      <td class="text-right">{{inputInfo.user_approval}}</td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col sm="12" md="7">
                <form-date :disabled="!cP(120)" @input="getBalance" :rules="{required: true}" v-model="date" label="Tanggal"></form-date>
              </b-col>
              <b-col sm="12">
                <hr>
              </b-col>
              <b-col sm="12">
                <b-row class="mb-3">
                  <b-col sm="4">
                    <small class="font-weight-bold">Saldo Kas</small>
                  </b-col>
                  <b-col sm="3">
                    <form-input-number disabled size="sm" label="" :value="balance"></form-input-number>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="3">
                    <h6>Biaya</h6>
                    <hr>
                  </b-col>
                  <b-col sm="4" class="text-center">
                    <h6>Draft</h6>
                    <hr>
                  </b-col>
                  <b-col v-if="formStatus==2" sm="4" class="text-center">
                    <h6>Persetujuan</h6>
                    <hr>
                  </b-col>
                </b-row>
                <b-row v-for="(tr,i) in details" :key="`details`+i">
                  <b-col sm="3">
                    <form-select-nested :disabled="tr.id>0" size="sm" :clearable="false" ref="costSelect" url="v1/expense_select2" @value-changed="costChangeRow($event,i)" :rules="{required: true}" v-model="tr.id_expense" label=""></form-select-nested>
                  </b-col>
                  <b-col sm="4" class="d-flex align-content-stretch">
                    <b-form-checkbox :disabled="formStatus==2" v-model="tr.is_paid" :value="1" :unchecked-value="0" class="mr-1"><small>Terbayar</small></b-form-checkbox>
                    <form-input-number :disabled="formStatus==2" class="w-50 mr-1" size="sm" label="" v-model="tr.amount"></form-input-number>
                    <form-input :disabled="formStatus==2" class="w-100" size="sm" :rules="{required: true}" v-model="tr.note" label="" placeholder="Catatan"></form-input>
                  </b-col>
                  <b-col sm="4" class="d-flex align-content-stretch" v-if="formStatus==2">
                    <b-form-checkbox v-model="approveDetails[i].is_paid" :value="1" :unchecked-value="0" class="mr-1"><small>Terbayar</small></b-form-checkbox>
                    <form-input-number :rules="{required: true}" class="w-50 mr-1" size="sm" label="" placeholder="Amount" v-model="approveDetails[i].amount"></form-input-number>
                    <form-input class="w-100" size="sm" v-model="approveDetails[i].note" :rules="{required: true}" label="" placeholder="Catatan"></form-input>
                  </b-col>
                  <b-col sm="1">
                    <b-button v-if="i>0&&!tr.id" size="sm" variant="danger" @click="deleteReduction(i)">
                      x
                    </b-button>
                    <b-button v-if="details.length-1==i" size="sm" variant="primary" @click="addReduction">
                      +
                    </b-button>
                  </b-col>
                </b-row>
                <b-row class="">
                  <b-col sm="3">
                    <hr>
                    <small class="font-weight-bold">Total Terbayar</small>
                  </b-col>
                  <b-col sm="4">
                    <hr>
                    <form-input-number disabled size="sm" label="" :value="totalExpensePaid"></form-input-number>
                  </b-col>
                  <b-col sm="4" v-if="formStatus==2">
                    <hr>
                    <form-input-number disabled size="sm" label="" :value="totalExpensePaidApprove"></form-input-number>
                  </b-col>
                </b-row>
                <b-row class="">
                  <b-col sm="3">
                    <small class="font-weight-bold">Total Belum Terbayar</small>
                  </b-col>
                  <b-col sm="4">
                    <form-input-number disabled size="sm" label="" :value="totalExpense"></form-input-number>
                  </b-col>
                  <b-col sm="4" v-if="formStatus==2">
                    <form-input-number disabled size="sm" label="" :value="totalExpenseApprove"></form-input-number>
                  </b-col>
                </b-row>
                <b-row class="">
                  <b-col sm="3">
                    <small class="font-weight-bold">Total Biaya</small>
                  </b-col>
                  <b-col sm="4">
                    <form-input-number disabled size="sm" label="" :value="total"></form-input-number>
                  </b-col>
                  <b-col sm="4" v-if="formStatus==2">
                    <form-input-number disabled size="sm" label="" :value="totalApprove"></form-input-number>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col sm="3">
                    <small class="font-weight-bold">Saldo Akhir</small>
                  </b-col>
                  <b-col sm="4">
                    <form-input-number disabled size="sm" label="" :value="totalSaldo"></form-input-number>
                  </b-col>
                  <b-col sm="4" v-if="formStatus==2">
                    <form-input-number disabled size="sm" label="" :value="totalSaldoApprove"></form-input-number>
                  </b-col>
                </b-row>
              </b-col>

              <b-col sm="12">
                <hr>
                <form-textarea :rules="{required: false}" v-model="note" label="Catatan"></form-textarea>
              </b-col>
              <b-col sm="12" class="mt-3">
                <button-save :isloading="isloading" :text="formStatus==2 ? 'Approve':($route.query.quickAction ? 'Simpan dan Lanjutkan':'Simpan')"></button-save>
                <button-back class="ml-1" :to="{name:'operationalouts'}"></button-back>
              </b-col>
            </b-row>
          </form-validation>
        </template>
      </my-card>
    </b-col>
  </b-row>
</template>

<script>
import FormTextarea from '@/my-components/FormTextarea.vue'
import FormDate from '@/my-components/FormDate.vue'
import FormInputNumber from '@/my-components/FormInputNumber.vue'
import FormInput from '@/my-components/FormInput.vue'
import FormSelect from '@/my-components/FormSelect.vue'
import FormSelectNested from '@/my-components/FormSelectNested.vue'
import ButtonSave from '@/my-components/ButtonSave.vue'
import ButtonBack from '@/my-components/ButtonBack.vue'
import { mapActions } from 'vuex'
import FormValidation from '@/my-components/FormValidation.vue'
import {BButton,BFormCheckbox} from 'bootstrap-vue'
import _ from 'lodash'

export default {
  components:{
    FormDate,
    ButtonSave,
    ButtonBack,
    FormValidation,
    FormSelect,
    FormInputNumber,
    FormTextarea,
    FormSelectNested,
    BButton,
    BFormCheckbox,
    FormInput
  },
  data(){
    return {
      lodash:_,
      isloading: false,
      cardloading: false,
      id: this.$route.params.id||null,
      code: "",
      id_branch: "",
      branch_name: "",
      balance: 0,
      add_capital: 0,
      note:"",
      date: this.$moment().format(),
      details: [],
      approveDetails: [],
      inputInfo: {}
    }
  },
  computed:{
    formStatus(){
      const meta = this.$route.meta
      if(meta.isEdit) return 1
      else if(meta.isApprove) return 2
      else return 0
    },
    total(){
      return _.sumBy(this.details,n => (parseFloat(n.amount)))
    },
    totalExpense(){
      let amount = 0
      for (let i = 0; i < this.details.length; i++) {
        const e = this.details[i];
        if(!e.is_paid) amount+=e.amount
      }
      return amount
    },
    totalExpensePaid(){
      let amount = 0
      for (let i = 0; i < this.details.length; i++) {
        const e = this.details[i];
        if(e.is_paid) amount+=e.amount
      }
      return amount
    },
    totalSaldo(){
      return this.balance - this.totalExpense
    },
    totalApprove(){
      return _.sumBy(this.approveDetails,n => (parseFloat(n.amount)))
    },
    totalExpenseApprove(){
      let amount = 0
      for (let i = 0; i < this.approveDetails.length; i++) {
        const e = this.approveDetails[i];
        if(!e.is_paid) amount+=e.amount
      }
      return amount
    },
    totalExpensePaidApprove(){
      let amount = 0
      for (let i = 0; i < this.approveDetails.length; i++) {
        const e = this.approveDetails[i];
        if(e.is_paid) amount+=e.amount
      }
      return amount
    },
    totalSaldoApprove(){
      return this.balance -+ this.totalExpenseApprove
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'operationalouts/store',
      dispatchShow: 'operationalouts/show',
      dispatchUpdate: 'operationalouts/update',
      dispatchApprove: 'operationalouts/approve',
      dispatchPartnerGet: 'partners/getSelect2'
    }),
    async getData(){
      this.cardloading = true
      if(this.formStatus>0){
        Promise.all([
          this.dispatchShow(this.id),
          // this.dispatchPartnerGet()
        ]).then(result => {
          const data = result[0]
          const {user_create,user_update,user_approval} = data
          this.inputInfo = {user_create,user_update,user_approval}

          this.code = data.code
          this.id_branch = data.id_branch
          this.branch_name = data.branch_name
          this.date = data.date
          this.note = data.note
          this.balance = data.balance

          let details = JSON.parse(JSON.stringify(data.operational_out_details))
          this.details = details
          let approveDetails = JSON.parse(JSON.stringify(data.id_status==1?data.operational_out_details:data.operational_out_detail_proves))
          while (approveDetails?.length < this.details?.length) {
            approveDetails.push({
                id: null,
                id_operational_out_detail: null,
                id_expense: null,
                expense_name: '',
                amount: 0,
                note: '',
                is_paid: 0
            });
          }
          
          this.approveDetails = approveDetails.map((d,x) => {
            return Object.assign({},d,{
              id_operational_out_detail: details?.[x]?.id || null,
              id_expense: details?.[x]?.id_expense || null,
              expense_name: details?.[x]?.expense_name || ''
            })
          })
          for (let x = 0; x < approveDetails.length; x++) {
            const appr = approveDetails[x];
            const det = details[x]
            if(!det) this.details.push(Object.assign({},appr,{note:'',amount:0,is_paid:0}))
          }
          if(data.id_branch) this.$refs.branchSelect.initOption([{value:data.id_branch,label:data.branch_name}])
          for (let x = 0; x < this.details.length; x++) {
            const e = this.details[x];
            this.$nextTick(() => {
              this.$refs.costSelect[x].initOption([{value:e.id_expense,label:e.expense_name}])
            })
          }
        })
      } else {
        const defaults = this.$store.state.auth
        this.$refs.branchSelect.initOption(defaults.defaultbranches)
        this.id_branch = defaults.profile.id_branch
        this.branch_name = defaults.profile.branch_name
        this.addReduction()

        Promise.all([
          this.getBalance()
        ])
      }
      this.cardloading = false
    },
    async submitForm(){
      this.isloading = true
      const {id,code,id_branch,branch_name,date,note,details,balance} = this
      let params = {id,code,id_branch,branch_name,date,note,details,balance}
      params.total_expense = this.totalExpense
      params.total_expense_paid = this.totalExpensePaid
      params.total = this.total
      if(this.formStatus==2){
        params.details = JSON.parse(JSON.stringify(this.approveDetails))
        for (let x = 0; x < params.details.length; x++) {
          delete params.details[x].id
        }
        delete params.id_branch
        delete params.branch_name
        delete params.total_expense
        delete params.total_expense_paid
        delete params.total
        params.total_expense_proved = this.totalExpenseApprove
        params.total_expense_paid_proved = this.totalExpensePaidApprove
        params.total_proved = this.totalApprove
      }
      try {
        if (this.formStatus==1) await this.dispatchUpdate(params);
        else if (this.formStatus==2) await this.dispatchApprove(params);
        else await this.dispatchStore(params);
        this.toastSuccess("OK","Data berhasil disimpan!")
        if(this.$route.query.quickAction) this.$router.push({name:'stockouts-add', query:{quickAction: true}})
        else this.$router.push({name:'operationalouts'})
      } catch (error) {
        this.handleError(error)
      }
      this.isloading = false
    },
    addReduction(){
      this.details.push({
        "id_expense" : null,
        "expense_name" : "",
        "note": "",
        "amount": 0,
        "is_paid" : 0
      })
      if(this.formStatus==2){
        this.approveDetails.push({
          "id_expense" : null,
          "expense_name" : "",
          "note": "",
          "amount": 0,
          "is_paid" : 0
        })
      }
    },
    costChangeRow(e,row){
      this.details[row].expense_name = e.label
      this.details[row].amount = e.standart_price
      const indexAppr = this.approveDetails[row]
      if(typeof(indexAppr)!=='undefined') {
        this.approveDetails[row].id_expense = e.value
        this.approveDetails[row].expense_name = e.label
        this.approveDetails[row].amount = e.standart_price
      }
    },
    deleteReduction(row){
      this.details.splice(row, 1)
      if(this.formStatus==2) this.approveDetails.splice(row, 1)
    },
    async getBalance(){
      const {id_branch, date} = this
      try {
        const { data } = await this.$http.get(`v1/operational_balance_get`,{params:{id_branch,date}})
        this.balance = data.balance.balance
        return Promise.resolve(data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
  mounted(){
    this.getData()
  }
}
</script>

<style>

</style>